import { useState } from "react";
import SideBar from "../../experience/SideBar"
import { Link } from "react-router-dom";

const PaymentError = () => {
  const [open, setOpen] = useState(false);
  return (
    <div className="bg-white">
      <header className="absolute inset-x-0 top-0 z-50">
        <SideBar open={open} setOpen={setOpen} />
      </header>
      <div className="flex flex-col justify-center items-center mt-10">
      <div
          className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
          aria-hidden="true"
        >          <h1>We had an error processing your payment</h1>
          <p>We are following the operation to find issues.</p> 
          <p>Please try again.</p>
          <Link to="/user_profile" className="text-blue-500">Go back to your profile</Link>
        </div>
      </div>
    </div>
  );
};

export default PaymentError;

import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../../context/auth-context';


const withAuth = <P extends object>(
  WrappedComponent: React.ComponentType<P>
) => {
  return (props: P) => {
    const { userState, loading } = useAuth();

    if (loading) {
      return <div>Loading...</div>; // Or a loading spinner
    }

    if (!userState.user) {
      return <Navigate to="/login" replace />;
    }

    return <WrappedComponent {...props} />;
  };
};

export default withAuth;
import { Link } from "react-router-dom";
import SideBar from "../../experience/SideBar";
import { useState } from "react";

const PaymentSuccess = () => {
  const [open, setOpen] = useState(false);
  return (
    <div className="bg-white">
      <header className="absolute inset-x-0 top-0 z-50">
        <SideBar open={open} setOpen={setOpen} />
      </header>
      <div className="flex flex-col justify-center items-center mt-10">
      <div
          className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
          aria-hidden="true"
        >
        <div className="flex flex-col justify-center	">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M12 6v6h4.5m4.5 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
            />
          </svg>

          <h2>Payment was successfully procesed</h2>
          <p>
            It migth take some time to be reflected in your account.
          </p>
        </div>
          <Link to="/user_profile" className="text-blue-500">Go back to your profile</Link>
        </div>
      </div>
    </div>
  );
};

export default PaymentSuccess;

export enum streamHooks {
    poolUpdate = 'poolUpdate',
    carUpdate= 'carUpdate',
    loggedIn = 'loggedIn',
    identityVerified='identityVerified',
    stakeIn = 'stakeIn',
    userStakeIn = 'userStakeIn',

    stripePayment = 'stripePayment',
    stripePaymentProcessing = 'stripePaymentProcessing',
    stripePaymentConfirmed = 'stripePaymentConfirmed',
    stripePaymentFailed = 'stripePaymentFailed',
    registeringOnBlockchain = 'registeringOnBlockchain',
    blockchainConfirmed = 'blockchainConfirmed',

}
// src/components/UserProfile.tsx

import React, { useEffect, useState } from "react";
import UserPaymentIntents from "./UserPaymentIntents";

import UserStakes from "../profile/userStakes";
import Login from "../auth/Login";
import { clearUser } from "../../services/users/userSlice";
import { useNavigate } from "react-router-dom";
import Stake from "../stake/Stake";
import SideBar from "../experience/SideBar";
import StartVerificationButton from "../../components/elements/StartVerificationButton";
import { useAuth } from "../../context/auth-context";
import UserWallets from "./UserWallet";
import { getUserPoolState } from "../../services/pools/poolRest";
import GradientDonutChart from "./GradientDonutChart";
import Unstake from "../profile/Unstake";

function UserProfile() {

  const {logout} = useAuth();

  const [open, setOpen] = useState<boolean>(false);

  const { userState, refreshUserData } = useAuth(); // useContext to access auth context
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const [goldStakes, setGoldStakes] = useState<undefined | {stake:string; gains:string;}>(undefined)

  const [riskStakes, setRiskStakes] = useState<undefined | {stake:string; gains:string;}>(undefined)


  useEffect(()=>{
    const getData = async () => { 

      const gold = process.env.REACT_APP_ADDRESS_PGOLD || '0x0'
      const risk = process.env.REACT_APP_ADDRESS_PRISK || '0x0'
  
      if (!userState?.user?.wallet || userState.user.wallet.length === 0) return console.error('No wallet address');
      const goldResponse = await getUserPoolState(gold, userState.user.wallet[0]);
      const riskResponse = await getUserPoolState(risk, userState.user.wallet[0]);
  
      setGoldStakes(goldResponse)
      setRiskStakes(riskResponse)
    };
    getData()
    refreshUserData()
  },[])

  if (!userState.user) return <Login />;
  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;

  return (
    <div className="bg-white">
      <header className="absolute inset-x-0 top-0 z-50">
        <SideBar open={open} setOpen={setOpen} />
      </header>

      <div className="flex flex-col justify-start items-center mt-20 mx-auto">
        <div className="flex md:w-[800px]">
          <div className="flex flex-col w-full  justify-start border-solid border-2 border-indigo-600  p-5 mx-5 rounded-xl">
            <h2 className="mb-3">
              <b>Your profile</b>
            </h2>
            <p>{userState.user.email}</p>
            {userState.user.status === "unverified" && (
              <span className="inline-flex items-center rounded-md bg-red-50 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/10 my-1">
                Account not verified
              </span>
            )}
            {userState.user.status === "unverified" && <StartVerificationButton />}
          <UserWallets userId={userState.user.id} />
          
            <div>
            <button
              onClick={logout}
              className="mt-10 ml-auto flex justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 "
              >
              Log Out
            </button>
            </div>
          </div>
        </div>

        {((goldStakes?.stake || riskStakes?.stake) &&  (goldStakes?.stake !== "0" || riskStakes?.stake !== "0")) &&
            <div className="flex md:flex-row flex-col">
              <GradientDonutChart stake={goldStakes?.stake || "0"} gains={goldStakes?.gains || "0"} pool={"Gold"}/>
              <GradientDonutChart stake={riskStakes?.stake || "0"} gains={riskStakes?.gains || "0"} pool={"Fresh"}/>
            </div>
          }

          {/* {((goldStakes?.stake || riskStakes?.stake) &&  (goldStakes?.stake !== "0" || riskStakes?.stake !== "0")) &&
            <div className="flex md:flex-row flex-col">
              <Unstake stake={goldStakes?.stake || "0"} gains={goldStakes?.gains || "0"} pool={"Gold"}/>
              <Unstake stake={riskStakes?.stake || "0"} gains={riskStakes?.gains || "0"} pool={"Fresh"}/>
            </div>
          } */}

        <div className="flex md:w-[800px] px-5 mb-5">
          <UserPaymentIntents />
        </div>

        {/* <div className="flex md:w-[800px] px-5">
          <UserStakes />
        </div> */}

      </div>
    </div>
  );
}

export default UserProfile;

import { useState, useEffect } from "react";
import { useAuth } from "../../context/auth-context";
import SideBar from "../experience/SideBar";
import { Link, useParams } from "react-router-dom";
import { AssetName } from "../commons/BlockAddress";
import PoolStakeEvents from "./PoolStakeEvents";
import BlockMoney from "../commons/BlockMoney";
import { useQuery } from 'react-query';
import { CarDetail } from '../../services/cars/carTypes';
import { EventsPoolStakeDto, PoolDto } from '../../services/pools/poolTypes';
import { getPoolStakeEvents, getUserPoolState } from '../../services/pools/poolRest';
import { getCarLoansList } from '../../services/cars/carsRest';


import { getPool } from '../../services/pools/poolRest';
import CustomProductDisplay from "../stripecheckout/CustomPrices";


const PoolCars = () => {
  const [open, setOpen] = useState(false);
  const { userState, refreshUserData } = useAuth(); // useContext to access auth context
  const { poolAddress } = useParams();

  if (!poolAddress) throw new Error("No poolAddress");

  const { data: stakingEvents, isLoading: stakingEventsLoading, error: stakingEventsError } = useQuery<EventsPoolStakeDto[], Error>(
      ['poolStakeEvents', poolAddress],
      () => getPoolStakeEvents(poolAddress)
    );

  const { data: carList, isLoading: carListLoading, error: carListError } = useQuery<CarDetail[], Error>(
      ['carLoansList', poolAddress],
      () => getCarLoansList(poolAddress)
    );

  const { data: poolDto, isLoading: poolDtoLoading, error: poolDtoError } = useQuery<PoolDto, Error>(
    ['poolDto', poolAddress],
    () => getPool(poolAddress)
  );

  const [userStakes, setUserStakes] = useState<undefined | {stake:string; gains:string;}>(undefined)

  useEffect(() => {
    const getData = async () => { 
      if (!userState?.user?.wallet || userState.user.wallet.length === 0) return console.error('No wallet address');
      const response = await getUserPoolState(poolAddress, userState.user.wallet[0]);
      setUserStakes(response)
    };
    getData();
  }, [userState?.user?.wallet]);

  const cardefierAddress = process.env.REACT_APP_ADDRESS_CARDEFIER || '0x0';

  const [cardefierStakes, setCardefierStakes] = useState<undefined | {stake:string; gains:string;}>(undefined)

  useEffect(() => {
    if (cardefierAddress === '0x0') return
    console.error('cardefierAddress not set cardefierAddress:', cardefierAddress);
    const getData = async () => {
      const response = await getUserPoolState(poolAddress, cardefierAddress);
      setCardefierStakes(response)
    };
    getData();
  }, [cardefierAddress]);

  if (stakingEventsLoading || carListLoading) return <div>Loading...</div>;
  if (stakingEventsError) return <div>Error loading staking events: {stakingEventsError.message}</div>;
  if (carListError) return <div>Error loading car loans: {carListError.message}</div>;

  return (
    <div className="bg-white">
      <header className="absolute inset-x-0 top-0 z-50">
        <SideBar open={open} setOpen={setOpen} />
      </header>
      <div className="flex flex-col justify-center items-center mt-10">
        <div className={`mx-auto mt-5 md:mt-14 rounded-xl  sm:mt-20 lg:mx-0 lg:flex`}>
          <div className="p-4 md:p-8 lg:flex-auto w-full">
            <h3 className="text-2xl font-bold tracking-tight text-gray-900">
              {poolDto?.name}
            </h3>
            {userState?.user?.id ?
            <CustomProductDisplay 
              successUrl="payment/success"
              cancelUrl="payment/error"
              poolAddress={poolAddress}
              userId={userState.user.id}
             />
              :
            <div className="w-full h-[200px] rounded-xl bg-slate-100 flex justify-center items-center">
              Login to stake in this pool
            </div>
            }
             
            
            { poolDto !== undefined &&
              <div className="">
                <BlockMoney
                  type={"address"}
                  money={poolDto?.cash}
                  tip={"cash:"}
                />
                <BlockMoney
                  type={"address"}
                  money={poolDto?.gained}
                  tip={"gained:"}
                />
                <BlockMoney
                  type={"address"}
                  money={poolDto?.accrued}
                  tip={"accrued:"}
                />
                <BlockMoney
                  type={"address"}
                  money={poolDto?.staked}
                  tip={"staked:"}
                />
                <BlockMoney
                  type={"address"}
                  money={poolDto?.gainedCollected}
                  tip={"gainedCollected:"}
                />
                <BlockMoney
                  type={"address"}
                  money={poolDto?.principalRemaining}
                  tip={"principal Remaining:"}
                />
                <BlockMoney
                type={"address"}
                money={poolDto?.interestsCollected}
                tip={"interestsCollected:"}
              />
                {/* <pre>
                  { JSON.stringify(poolDto, null,2)}
                </pre>                 */}
              </div>
            }

            {carList?.map((car) => (

              
              <div key={`-index--${car.carLoanContract?.carloan}`} className="shadow-xl m-3 p-3 ring-1 ring-gray-900/5 flex flex-col md:flex-row py-5">
                <div>
                  <Link to={"/carloan/state/" + car.carloan}>
                    <div className="md:flex">
                      <img
                        className="flex-none rounded-full bg-gray-50"
                        style={{ width: "200px", borderRadius: "5px" }}
                        src={car?.images[0]}
                        alt=""
                      />
                    </div>
                  </Link>
                </div>

                <div className="flex flex-col w-full p-2">
                  <div className="flex flex-row ml-auto">
                    <div className="flex-none rounded-full bg-emerald-500/20 p-1">
                      <div className="h-2.5 w-2.5 rounded-full bg-emerald-500"></div>
                    </div>
                    <p className="text-xs leading-5 text-gray-500">Performing</p>
                  </div>

                  <Link to={"/carloan/state/" + car.carloan}>
                    <div className="text-sm font-semibold leading-6 text-gray-900">
                      <AssetName name={`${car.brand} ${car.model} ${car.year}`}></AssetName>
                    </div>
                  </Link>

                  <BlockMoney
                    type={"address"}
                    money={car.principalRemaining}
                    tip={"Remaining Balance:"}
                  ></BlockMoney>
                </div>
              </div>
            ))}
          </div>
        </div>
        <PoolStakeEvents stakingEvents={stakingEvents || []} />
      </div>
    </div>
  );
};

export default PoolCars;
